import React from 'react'
import { throttle } from 'lodash'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.svgRef = React.createRef()
    this.state = {
      page: { width: 0, height: 0 },
      opacity: { x: 0.5, y: 0.5, z: 0.5 },
    }
  }

  componentDidMount() {
    this.setPageDimensions()
    window.addEventListener('mousemove', this.setBackground)
    window.addEventListener('resize', this.setPageDimensions)

    // if touch device & motion...
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', this.deviceOrientation)
    }

    setTimeout(() => {
      this.svgRef.current.classList.add('-animate')
    }, 1000)
  }

  setPageDimensions = () => {
    this.setState({ page: {
      width: window.innerWidth,
      height: window.innerHeight
    }})
  }

  setBackground = (ev) => {
    const calc = (a, b) => (((0.5 / a) * b) + 0.25)

    const x = calc(this.state.page.width, ev.pageX)
    const y = calc(this.state.page.height, ev.pageY)
    const z = ((1 - x) + y) / 2

    this.setState({ opacity: { x, y, z }})
  }

  deviceOrientation = throttle(({ alpha, gamma, beta }) => {
    // let y = alpha - 180
    // if (y < 0) y = y * -1
    // y = (y / 180)

    // let x = (gamma + 100) / 200
    // if (x < 0.25) x = 0.25

    // let z = (beta + 100) / 200
    // if (z < 0.25) z = 0.25


    let x = gamma
    if (x < -40) x = -40
    if (x > 40) x = 40
    x = (x + 40) / 80

    let y = beta
    if (y < 30) y = 30
    if (y > 110) y = 110
    y = 1 - ((y - 30) / 80)

    const z = ((1 - x) + y) / 2

    this.setState({ opacity: { x, y, z } })
  }, 17) // 17 =~ 60fps

  render() {
    return (
      <div className="home">
        <div className="solid"></div>
        <div className="grad -x" style={{ opacity: this.state.opacity.x }}></div>
        <div className="grad -y" style={{ opacity: this.state.opacity.y }}></div>
        <div className="grad -z" style={{ opacity: this.state.opacity.z }}></div>

        <svg ref={this.svgRef} width="250" height="250">
          <path className="c1" d="M175 75 L125 25 L25 125 L75 175" />
          <path className="y1" d="M225 125 L125 225 L75 175" />
          <path className="y2" d="M175 175 L125 125 L175 75" />
        </svg>
      </div>
    )
  }
}

export default App
